import React, { Component, useState } from 'react';
import Nav from "../components/nav";
import Footer from "../components/footer";
import Hero from "../components/hero";
import { Helmet } from "react-helmet";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import image1 from "../images/chirophysio/image1.png"
import image2 from "../images/chirophysio/image2.png"
import image3 from "../images/chirophysio/image3.jpg"
import image4 from "../images/chirophysio/image4.png"
import imageactive1 from "../images/active-1.png"
import imageactive2 from "../images/active-2.png"
import imageactive3 from "../images/active-3.png"
import Arrow from "../images/arrow-right-white.svg"
import { useCollapse } from "react-collapsed";

import mainImage from "../images/rehab-clinic/htr54444.jpeg"
import coachImage from "../images/rehab-clinic/image7.png"
import podcastThree from "../images/rehab-clinic/image8.png"
import podcastOne from "../images/rehab-clinic/image6.png"
import podcastFive from "../images/rehab-clinic/image4.png"
import podcastTwo from "../images/rehab-clinic/image2.png"
import podcastFour from "../images/rehab-clinic/image1.png"
import podcastSix from "../images/rehab-clinic/image1v2.png"
import bannerImage from "../images/rehab-clinic/image4.jpg"

import LogoWhite from "../images/header-logo.png"
import {Link} from 'gatsby'
import ExpandArrow from "../images/expand-arrow.png"
import TopBar from "../components/top-bar";

function NavActiveCare(props) {
  let highlight = "";
  if (props.home === true){
      highlight = "border-b-4 border-b-accent"
  }
  function ChangeCheckbox(){
      const element = document.getElementById('menu-toggle');
      element.checked = !element.checked;
  }

  function OpenMobileSubMenuServices(){
      OpenMobileSubMenu("mobile-services");
  }

  function OpenMobileSubMenuAbout(){
      OpenMobileSubMenu("mobile-about");
  }

  function OpenMobileSubMenu(id_of_elem){
      const element = document.getElementById(id_of_elem);
      if (element.style.display === "none") {
          element.style.display = "block";
      } else {
          element.style.display = "none";
      }
  }

  // function MakeVisible(){
  //     const element = document.getElementById("big-group");
  //     element.classList.remove("hidden");
  // }
  //
  // function MakeInvisible(){
  //     const element = document.getElementById("big-group");
  //     element.classList.add("hidden");
  // }

  return (
      <>
      <div className={"bg-white h-16 lg:h-20 sticky top-0 transition duration-500 z-10"}>
          <nav className="flex justify-between h-full m-auto z-10">
              <Link className="h-12 lg:h-20 w-auto my-auto pl-4 z-10" to={"/"}>
                  <img className="h-12 lg:h-20 lg:p-3 lg:pl-10 w-auto my-auto pl-4 z-10" alt="kinetic impact logo" width="365"
                       height="95"
                       src={LogoWhite}/></Link>

              <ul className="text-xl justify-between items-center hidden lg:flex lg:ml-10 z-20 pr-4 lg:pr-20 whitespace-nowrap">
                  <li className={highlight + " hover:bg-accent px-3 h-full w-full flex justify-center items-center"}><Link to="/">Home</Link></li>
                  <li className="group relative h-full w-full flex align-middle items-center  hover:bg-accent px-3">
                      <div className={"bg-accent absolute w-auto h-auto left-0 top-20 group-hover:visible invisible"} >{/*onMouseLeave={MakeInvisible}*/}
                          <Link to={"/chiropractic-and-physical-therapy"}><div className={"p-4 hover:bg-gray-100"}><p>Chiropractic & Physical Therapy</p></div></Link>
                          <Link to={"/return-to-sport-testing"}><div className={"p-4 hover:bg-gray-100"}><p>Return to Sport Testing</p></div></Link>
                          <Link to={"/one-on-one-coaching"}><div className={"p-4 hover:bg-gray-100"}><p>1 on 1 Personal Training</p></div></Link>
                          <Link to={"/small-group-training"}><div className={"p-4 hover:bg-gray-100"}><p>Semi Private Training</p></div></Link>
                          <Link to={"/youth-group-class"}><div className={"p-4 hover:bg-gray-100"}><p>Youth Athletic Development</p></div></Link>

                          {/*<div className={"p-4 flex justify-between select-none hover:bg-gray-100 peer"} onClick={OpenSubMenuGroup}><p>Group Classes</p><img className={"w-5 h-5 mt-2"} src={Arrow} alt={"arrow"} /></div>*/}
                          {/*<div id={"big-group"} className={"w-full bg-white peer-hover:block hover:block hidden"} onMouseEnter={MakeVisible}>*/}
                          {/*    <Link to={"/youth-group-class"}><div className={"hover:bg-gray-100"}><p className={"p-4 pl-10"}>Youth Group Classes</p></div></Link>*/}
                          {/*    <Link to={"/adult-group-class"}><div className={"hover:bg-gray-100"}><p className={"p-4 pl-10"}>Adult Group Classes</p></div></Link>*/}
                          {/*</div>*/}
                          {/*<Link to={"/wellness-and-recovery"}><div className={"p-4 hover:bg-gray-100"}><p>Wellness & Recovery</p></div></Link>*/}

                      </div>
                      <Link className={""} to="">Services</Link></li>
                  <li className="group relative h-full w-full flex align-middle items-center  hover:bg-accent px-3">
                      <div className={"bg-accent absolute w-auto h-auto left-0 top-20 group-hover:visible invisible"} >{/*onMouseLeave={MakeInvisible}*/}
                          <Link to={"/about"}><div className={"p-4 hover:bg-gray-100"}><p>About Us</p></div></Link>
                          <Link to={"/testimonials"}><div className={"p-4 hover:bg-gray-100"}><p>Testimonials</p></div></Link>
                          <Link to={"/frequently-asked-questions"}><div className={"p-4 hover:bg-gray-100"}><p>FAQs</p></div></Link>
                      </div>
                      <Link className={""} to="">About</Link></li>
                  {/*<li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/about">About</Link></li>*/}
                  <li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/contact">Contact</Link></li>
                  <li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/blog">Blog</Link></li>
                  {/* <li className=" hover:bg-accent px-3 h-full flex align-middle items-center"><Link to="/low-back-program">Low Back Program</Link></li> */}
              </ul>

              <div className="relative lg:hidden p-4 pl-0">
                  <label htmlFor="menu-toggle"></label>
                  <input id="menu-toggle"
                         className="absolute block cursor-pointer w-12 top-3 h-12 z-[1000] opacity-0 peer select-none"
                         type="checkbox"/>
                  <div className={"fixed left-0 right-0 bottom-0 top-16 w-full transition duration-300 opacity-0 peer-checked:opacity-100 peer-checked:visible bg-accent invisible z-10000"}>
                      <Link to={"/"}><div className={"p-8 py-5 pt-24 border-b border-b-black/25 text-3xl"}>Home</div></Link>
                      <div className={"p-8 py-5 border-b border-b-black/25 text-3xl flex justify-between"} onClick={OpenMobileSubMenuServices}><p>Services</p><img className={"w-5 h-5 mt-2"} src={ExpandArrow} alt={"arrow"} /></div>
                      <div id={"mobile-services"} className={"w-full bg-white"} style={{display: "none"}}>
                          <Link to={"/chiropractic-and-physical-therapy"}><p className={"text-2xl p-4 pl-11"}>Chiropractic & Physical Therapy</p></Link>
                          <Link to={"/return-to-sport-testing"}><p className={"text-2xl p-4 pl-11"}>Return to Sport Testing</p></Link>
                          <Link to={"/one-on-one-coaching"}><p className={"text-2xl p-4 pl-11"}>1 on 1 Personal Training</p></Link>
                          <Link to={"/small-group-training"}><p className={"text-2xl p-4 pl-11"}>Semi Private Training</p></Link>
                          <Link to={"/youth-group-class"}><p className={"text-2xl p-4 pl-11"}>Youth Athletic Development
                          </p></Link>
                          {/*<div className={"text-2xl p-4 pl-11 flex justify-between"} onClick={OpenMobileSubMenuGroup}><p>Group Classes</p><img className={"w-5 h-5 mt-2"} src={Arrow} alt={"arrow"} /></div>*/}
                          {/*<div id={"mobile-group"} className={"w-full bg-white "} style={{display: "none"}}>*/}
                          {/*    <Link to={"/youth-group-class"}><p className={"text-2xl p-4 pl-16"}>Youth Group Classes</p></Link>*/}
                          {/*    <Link to={"/adult-group-class"}><p className={"text-2xl p-4 pl-16"}>Adult Group Classes</p></Link>*/}
                          {/*</div>*/}
                          {/*<Link to={"/wellness-and-recovery"}><p className={"text-2xl p-4 pl-11"}>Wellness & Recovery</p></Link>*/}

                      </div>
                      <div className={"p-8 py-5 border-b border-b-black/25 text-3xl flex justify-between"} onClick={OpenMobileSubMenuAbout}><p>About</p><img className={"w-5 h-5 mt-2"} src={ExpandArrow} alt={"arrow"} /></div>
                      <div id={"mobile-about"} className={"w-full bg-white"} style={{display: "none"}}>
                          <Link to={"/about"}><p className={"text-2xl p-4 pl-11"}>About Us</p></Link>
                          <Link to={"/testimonials"}><p className={"text-2xl p-4 pl-11"}>Testimonials</p></Link>
                          <Link to={"/frequently-asked-questions"}><p className={"text-2xl p-4 pl-11"}>FAQs</p></Link>

                      </div>
                      <Link to="/contact"><div className={"p-8 py-5 border-b border-b-black/25 text-3xl"}>Contact</div></Link>
                      <Link to="/blog"><div className={"p-8 py-5 border-b border-b-black/25 text-3xl"}>Blog</div></Link>
                      <Link to="/low-back-program"><div className={"p-8 py-5 border-b border-b-black/25 text-3xl"}>Low Back Program</div></Link>
                  </div>
                  <div className={"fixed w-full h-full bg-accent peer-checked:visible hidden"}>
                      <p>bla bla</p>
                  </div>

                  {/*<div className="fixed w-48 -top-4 -right-80 peer-checked:-translate-x-80 bg-white antialiased transition duration-500 ease-[cubic-bezier(0.77,0.02,0.05,1.0)] dark:bg-gray-900 z-10">
                      <div className="flex-col justify-evenly mt-32 mb-16 text-gray-500 dark:text-white">
                          <Link to="/" >
                              <div onClick={ChangeCheckbox}
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Home
                              </div>
                          </Link>
                          <Link to="/about">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">About
                              </div>
                          </Link>
                          <Link to="/#services">
                              <div onClick={ChangeCheckbox}
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Services
                              </div>
                          </Link>
                          <Link to="/comparison">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Before & After
                              </div>
                          </Link>
                          <Link to="/hiring">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Hiring
                              </div>
                          </Link>
                          <Link to="/contact">
                              <div
                                  className="p-4 mr-4 text-3xl text-right font-bold hover:text-gray-400 active:text-gray-400">Contact
                              </div>
                          </Link>
                      </div>
                  </div>*/}
                  <div className="relative  z-20 w-9 h-0.5 top-1.5 mb-2   bg-black transition rotate-0 duration-500  peer-checked:bg-gray-700 peer-checked:-translate-x-1 peer-checked:rotate-45 duration-300 origin-[11%_100%]"></div>
                  <div className="relative  z-20 w-7 h-0.5 top-1.5 mb-2 ml-2   bg-black peer-checked:opacity-0 peer-checked:scale-0 transition duration-300 origin-[0%_100%]"></div>
                  <div className="relative  z-20 w-9 h-0.5  top-1.5 bg-black transition rotate-0 duration-300  peer-checked:bg-gray-700 peer-checked:-rotate-45 duration-500 origin-[11%_100%] peer-checked:-translate-x-0.5"></div>
                  <div id="mobile-nav-click-off" className="fixed w-full h-full bg-transparent top-0 left-0 -z-10 hidden peer-checked:block" onClick={ChangeCheckbox}></div>
              </div>
          </nav>
      </div>
      </>

  )
}

function PayPalButtonStudent() {
    const addEmailToActiveCampaign = async (email) => {
        fetch(`/.netlify/functions/addEmailToActiveCampaign?email=${email}`)
        .then(response => response.text())
        .then(data => console.log(data));
      };

  return (
    <div className='w-full lg:w-[500px] p-5'>
    <PayPalScriptProvider options={{ "client-id": "AYGIC1nmdxe_eCE7-RDN_XoGcS269SRJ-wAXIuMx9-r_gEPVq5SvldtFQd3kwVGM-h4ImRGZ85RtakqV" }}>
      <PayPalButtons
      style={{ layout: "vertical" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: "350.00",
                },
              },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
          });
        }}
        onApprove={(data, actions) => {
            return actions.order.capture().then(function(details) {
              // Call your function to add email to ActiveCampaign
              addEmailToActiveCampaign(details.payer.email_address);
            });
          }}
      />
    </PayPalScriptProvider>
    </div>
  );
}


function PayPalButtonGeneral() {
    const addEmailToActiveCampaign = async (email) => {
        fetch(`/.netlify/functions/addEmailToActiveCampaign?email=${email}`)
        .then(response => response.text())
        .then(data => console.log(data));
      };
    return (
        <div className='w-full lg:w-[500px] p-5'>
      <PayPalScriptProvider options={{ "client-id": "AYGIC1nmdxe_eCE7-RDN_XoGcS269SRJ-wAXIuMx9-r_gEPVq5SvldtFQd3kwVGM-h4ImRGZ85RtakqV" }}>
        <PayPalButtons
        style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "450.00",
                  },
                },
              ],
              application_context: {
                  shipping_preference: "NO_SHIPPING"
              }
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(function(details) {
              // Call your function to add email to ActiveCampaign
              addEmailToActiveCampaign(details.payer.email_address);
            });
          }}
        />
      </PayPalScriptProvider>
      </div>
    );
  }


  
const TwoBuyButtons = () => {
    const [isVisible, setIsVisible] = useState(true)
    const [isSecondVisible, setIsSecondVisible] = useState(true)
    const [isGeneral, setIsGeneral] = useState(false)
    const [isStudent, setIsStudent] = useState(false)
  
    return (
      <>
        {isVisible ? (
            <p onClick={() => setIsVisible(false)} className={"bg-accent text-black px-9 py-6 m-2 text-3xl hover:bg-gray-100 group"}> Sign Up Now <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} />
            </p> 
        ) : (
        <>
            {isSecondVisible ? (
            <><a onClick={() => [setIsGeneral(true),setIsSecondVisible(false)]} className={"bg-accent text-black px-9 py-6 m-2 lg:text-3xl text-2xl hover:bg-gray-100 group"}> General Admission <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} />
            </a>
            <a onClick={() => [setIsStudent(true),setIsSecondVisible(false)]} className={"bg-accent text-black px-9 py-6 m-2 lg:text-3xl text-2xl hover:bg-gray-100 group"}> Student Admission <img loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"} />
            </a></>
            ) : (
                null
            )}
            {isGeneral ? (
            <PayPalButtonGeneral/>
            ) : (
                null
            )}
            {isStudent ? (
            <PayPalButtonStudent/>
            ) : (
                null
            )}
            </>
        )}
      </>
    )
  }

function InteractiveTrainingPrivate() {
    return (
        <div>
            <Helmet>
                <title>Kinetic Impact | Interactive Training and Rehabilitation Clinic with Coach Irving "Boo" Schexnayder</title>
      <meta property='og:type' content='website' />
<meta property='og:title' content='Kinetic Impact | Interactive Training and Rehabilitation Clinic with Coach Irving Boo Schexnayder' />
<meta property='og:url' content='https://kirehab.com/interactive-training-and-rehabilitation-clinic' />
<meta property='og:image' content={bannerImage} />
<meta property='og:description' content='
Come join us at Kinetic Impact where Irving "Boo" Schexnayder will be teaching a limited number of people over the weekend in an interactive format.
' />
            </Helmet>
            <NavActiveCare />
            <div className={"bg-black w-full"}>
                <div className={"max-w-6xl mx-auto flex flex-wrap text-white"}>
                    <div className='w-full p-5 lg:p-10 pb-0'>
                
                </div>
                    <div className='lg:w-1/2 w-full p-5 lg:p-10 pb-0 lg:pb-10'>
                    <p className='lg:text-5xl text-4xl text-center pb-5 leading-normal'>Interactive Training and Rehabilitation Clinic with Coach <span className='text-accent'>Irving "Boo" Schexnayder</span></p>
                        <p className='text-xl pb-3 leading-relaxed'>Come join us at Kinetic Impact where Irving "Boo" Schexnayder will be teaching a limited number of people over the weekend in an interactive format.</p>
                        <p className='text-xl pb-3 leading-relaxed'>Programming and Rehab Topics will include:</p>
                        <ul className='list-disc'>
                        <li className='text-xl p-1 ml-3'>Warming Up – Planning and Trends (Lab)</li>
                        <li className='text-xl p-1 ml-3'>Organizing Training by Neural Demand</li>
                        <li className='text-xl p-1 ml-3'>Teaching Plyometrics in the Training and Rehabilitation Settings (Lab)</li>
                        <li className='text-xl p-1 ml-3'>Speed Based Rehabilitation Principles</li>
                        <li className='text-xl p-1 ml-3'>Sprint Based Loading Schemes in Rehabilitation Settings</li>
                        <li className='text-xl p-1 ml-3'>Basic Teaching Progressions for Weightlifting Exercises (Lab)</li>
                        <li className='text-xl p-1 ml-3'>Restoration Circuits - Use and Construction</li>
                        <li className='text-xl p-1 ml-3'>Movement Screening to Find a Safe Entry Point for Training (Lab)</li>
                        <li className='text-xl p-1 ml-3'>Discussion of Application to your setting and Q&A</li>
                        </ul>
                    </div>
                    <div className='lg:w-1/2 w-full p-5 lg:p-10 lg:pt-12 pt-4 flex flex-col justify-evenly'>
                        <img className='rounded-xl' src={bannerImage} />
                        <div>
                            <p className='text-2xl pb-3 pt-5 leading-snug'><span className='text-accent font-bold'>Date </span> Saturday August 17th & Sunday August 18th</p>
                            <p className='text-2xl pb-3 leading-snug'><span className='text-accent font-bold'>Time </span> 12pm - 5pm Saturday. 8am to 4pm Sunday</p>
                            <p className='text-2xl pb-3 leading-snug'><span className='text-accent font-bold'>Venue </span> Kinetic Impact - 10806 Willow Ct Ste 2 San Diego Ca 92127</p>
                            <p className='text-2xl pb-3 leading-snug'><span className='text-accent font-bold'>Pricing </span> $450 General Admission $350 Student Admission</p>
                        </div>
                    </div>
                    <div className='py-10 pt-0 w-full flex flex-wrap justify-center'>
                        <TwoBuyButtons/>
                            </div>
                </div>
            </div>
            <div className='bg-white w-full'>
                <div className={" max-w-6xl mx-auto w-full p-5 lg:p-5 lg:pt-10"}>
                    <div className='flex lg:flex-row flex-col justify-between lg:gap-10 gap-5'>
                    <img className='rounded-xl self-center mt-10 lg:mt-0' src={coachImage} />
                    <div>
                        <p className='text-5xl pb-3 leading-relaxed'><span className='text-accent'>Coach </span> Boo</p>
                        <p className='text-xl pb-3 leading-relaxed'>Coach Boo is one of the leading authorities in training design and one of the world's premier field events coaches. He is known for his 18 years at LSU helping their track and field team produce 26 NCAA champions and 13 NCAA Championship teams. Internationally, he has coached 18 Olympians and 7 Olympic/World Championship medalists.</p>
                        <p className='text-xl pb-3 leading-relaxed'>If you are a personal trainer, strength and conditioning coach, physical therapist, athletic trainer, chiropractor, group fitness instructor or someone interested in learning more how to apply important training concepts, sign up for this impactful two day seminar. This workshop will be interactive and full of wisdom from Boo's 43 year coaching & consulting career.</p>
                    </div>
                </div>
                    {/* <p className='text-4xl pb-3 leading-relaxed'><span className='text-accent'>Course </span> Structure</p>
                    <p className='text-xl pb-3 leading-relaxed'>We will be diving into the evidence of high valued care in the world of musculoskeletal disorders. This will create the framework for the course as we share how we turn the evidence into a process you can apply with your client and/or patient.</p>
                    <p className='text-xl pb-3 leading-relaxed'>We will cover how we interview our clients, how we assess out clients and how we turn their goals or limitations into their rehab programming for long term results.</p>
                    <p className='text-xl pb-3 leading-relaxed'>This course is designed to provide an intimate learning experience where we encourage questions and dialogue to learn not on with but from each other.</p>
                    
                    <p className='text-4xl pb-3 pt-3 leading-relaxed'><span className='text-accent'>Course </span> Objectives</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Promote long term change in a climate with poor healthcare outcomes</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Understand pain and how to coach your client</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Implement a collaborative approach to behavior change</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Pivot from diagnosis to assessment and goal driven care</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> Understand how to use needs analysis to drive better rehab programming</p>
                    <p className='text-xl pl-3 pb-1 leading-relaxed'><span className='pr-3'>-</span> How to provide the support that builds trust to foster a long term relationship</p>
    
                    <p className='text-4xl pb-3 pt-5 leading-relaxed'><span className='text-accent'>What</span> does this mean for you?</p>
                    <p className='text-xl pb-3 leading-relaxed'>Your clients will WANT to come back and will be happy to adhere to the advice you give them. They become role models for their friends and family and will want to share this experience with everyone they know. You will be unshackled from the need to fix everyone you see because you will be able to show your clients THEY are the superhero and you are the sidekick.</p>
                    
                    <p className='text-4xl pb-3 pt-5 leading-relaxed'><span className='text-accent'>Who</span> is this course open to?</p>
                    <p className='text-xl pb-3 leading-relaxed'>Clinicians (PT, DC, OT, ATC, MD) and coaches looking to provide clients with long term results.</p>
                     */}
                    <div className='py-10 flex justify-center'>
                    <TwoBuyButtons/></div>
                </div>
            </div>
            <div className={"bg-black w-full pb-5 lg:pt-5"}>
            <p className='text-5xl p-5 pt-10 text-center text-white'>Previous Course <span className='text-accent'>Highlights</span></p>
                <div className={"max-w-6xl mx-auto flex flex-wrap text-white p-5 justify-evenly"}>
                    <div className='rounded-xl p-5 lg:w-1/2 w-full'>
                        <iframe className='aspect-video w-full' src="https://www.youtube.com/embed/8mtNS-PJJxY?si=o6koucOi_Tm-GbAF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className='rounded-xl p-5 lg:w-1/2 w-full'>
                        <iframe className='aspect-video w-full' src="https://www.youtube.com/embed/qE0A3OXXHTc?si=-u49gqZ-qDTy5kHo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className='rounded-xl p-5 lg:w-1/2 w-full'>
                        <iframe className='aspect-video w-full'  src="https://www.youtube.com/embed/hpWzvo-xkIs?si=ZoR6hnUzQR1oQdtJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                
                
                    <div className='pt-10 w-full flex flex-wrap justify-center'>
                        <TwoBuyButtons/>
                    </div>
                </div>
            </div>


            <div className={"w-full pb-5  lg:pt-5"}>
                <p className='text-5xl p-5 pt-10 text-center'><span className='text-accent'>Podcasts</span> with Coach Boo</p>
                <div className={"max-w-6xl mx-auto flex flex-wrap p-5 justify-evenly"}>
                    <a href='https://podcasts.apple.com/us/podcast/move-without-limits/id1612479438?i=1000638418078'><img className='rounded-xl self-center m-5 lg:mt-10' src={podcastOne} /></a>
                    <a href='https://podcasts.apple.com/us/podcast/move-without-limits/id1612479438?i=1000591860864'><img className='rounded-xl self-center m-5 lg:mt-10' src={podcastTwo} /></a>
                    <a href='https://podcasts.apple.com/us/podcast/the-track-and-field-performance-podcast/id1544810395?i=1000627478053'><img className='rounded-xl self-center m-5 lg:mt-10' src={podcastFour} /></a>
                    <a href='https://podcasts.apple.com/us/podcast/resilient-performance-podcast/id1213080342?i=1000507521910'><img className='rounded-xl self-center m-5 lg:mt-10' src={podcastThree} /></a>
                    <a href='https://podcasts.apple.com/us/podcast/just-fly-performance-podcast/id1121047188?i=1000552127931'><img className='rounded-xl self-center m-5 lg:mt-10' src={podcastSix} /></a>
                    <div className='pt-10 w-full flex flex-wrap justify-center'>
                        <TwoBuyButtons/>
                    </div>
                </div>
            </div>

            <div className={"bg-black w-full pb-5  lg:pt-5"}>
            <p className='text-5xl p-5 pt-10 pb-2 text-center text-white'>Written<span className='text-accent'> Articles & Resources</span> </p>
            <p className='text-xl text-center text-white'>(<a className='text-accent' href='https://www.sacspeed.com/wp/'>sacspeed.com</a> for more)</p>
                <div className={"max-w-6xl mx-auto flex flex-col text-white p-5 justify-around"}>
                    <a href='https://sacspeed.com/pdf/generalstrength.pdf'><p className='text-3xl text-accent hover:opacity-75 p-4'>Using General Strength in the Training Regiment</p></a>
                    <a href='https://sacspeed.com/pdf/SACinventory.pdf'><p className='text-3xl text-accent hover:opacity-75 p-4'>Boo Schexnadyer’s Complete Training Inventory</p></a>
                    <a href='https://sacspeed.com/pdf/Rehab.pdf'><p className='text-3xl text-accent hover:opacity-75 p-4'>Common Sense Rehabilitation Guidelines</p></a>
                    <a href='https://www.sacspeed.com/pdf/Training%20Management.pdf'><p className='text-3xl text-accent hover:opacity-75 p-4'>Training Management</p></a>
                    <a href='https://www.sacspeed.com/pdf/Hamstrings.pdf'><p className='text-3xl text-accent hover:opacity-75 p-4'>A Guide to Handling Hamstring Injuries for the Coach</p></a>
                    <div className='pt-10 w-full flex flex-wrap justify-center'>
                        <TwoBuyButtons/>
                    </div>
                </div>
            </div>


 



            <Footer />
        </div>
    )
}

export default InteractiveTrainingPrivate
